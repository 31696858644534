import { useCallback, useState } from "react";

export function useForm<T>(apiCallback: (data: T) => Promise<T> | string) {
  const [data, setData] = useState<T>({} as T)
  const [error, setError] = useState(false)
  const [response, setResponse] = useState<T>()

  const toDefault = useCallback(() => {
    setError(false)
    setResponse(undefined)
  }, [])

  const updateFields = useCallback((keys: Array<keyof T>, values: Array<any>) => {
    for (let i = 0; i < keys.length; i++) {
      data[keys[i]] = values[i]
    }
    setData(d => {
      for (let i = 0; i < keys.length; i++) {
        d[keys[i]] = values[i]
      }
      return d
    })
    toDefault()
  }, [data, toDefault])

  const updateField = useCallback((key: keyof T, value: any) => {
    setData(d => {
      d[key] = value
      return d
    })
    toDefault()
  }, [toDefault])

  const submitForm = useCallback(() => {
    const r = apiCallback(data)

    if (typeof r === 'string') {
      setError(true)
    } else {
      r.then(setResponse)
    }
  }, [apiCallback, data])

  return {
    data: data,
    error: error,
    response: response,
    updateField: updateField,
    updateFields: updateFields,
    submitForm: submitForm
  }
}